import { ProductDataPayload } from 'editor/src/store/variants/reducer/setProductDataReducer';
import { VariationGroup } from 'editor/src/store/variants/types';

function applyLinkedToGroups(
  groups: VariationGroup[],
  defaultVariationsInfo: ProductDataPayload['defaultVariationsInfo'],
) {
  if (defaultVariationsInfo) {
    groups.forEach((group) => {
      group.linked = group.variationsInfo[0]
        ? !!defaultVariationsInfo[group.variationsInfo[0].variation.productUid]?.linked
        : true;
    });
  }
}

export default applyLinkedToGroups;
