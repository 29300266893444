import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';

import { DesignData } from 'editor/src/store/design/types';
import loadDesignDataFonts from 'editor/src/store/fonts/utils/loadDesignDataFonts';
import { GalleryImage } from 'editor/src/store/gallery/types';
import { getDesignKeyFromDesign } from 'editor/src/store/variants/helpers/getDesignKey';
import { getGuestCustomerId } from 'product-personalizer/src/store/drafts/utils/guestStorage';

import loadImage from 'editor/src/util/loadImage';
import { createDraft } from 'product-personalizer/src/draftAPI';
import store from 'product-personalizer/src/store';
import { Asset } from 'product-personalizer/src/types/asset';
import { Design } from 'product-personalizer/src/types/design';
import { Variant } from 'product-personalizer/src/types/variant';
import { fetchDesign, fetchScenes, fetchVariants, generatePreview } from 'product-personalizer/src/utils/loadData';

import {
  createSpreadPreview,
  RequestRenderFn,
  SpreadPreview,
  SpreadPreviewBlob,
  SpreadPreviewDataURL,
} from 'editor/src/component/SpreadPreview';
import { SizeConstraints } from 'editor/src/component/SpreadPreview/createSpreadPreview';

import { LoadedAssets, LoadedScene } from './types';
import {
  assetsToGalleryImages,
  extractFirstUploadedAssetByDesignIds,
  formatDesignStructureForCanvasRendering,
  getNewDesignWithAppliedImage,
  getSceneNameFromProductUid,
  htmlImageToDesignAsset,
  htmlImageToGalleryImage,
  isSingleAreaScene,
  loadFont,
  loadSceneAssets,
} from './utils';
import createCanvasRendering from './utils/createCanvasRendering';
import createScenePreview from './utils/createScenePreview';

const DEFAULT_DIMENSION = 500;

interface ReflectDesignAssetForVariantReturnValue {
  variantId: string;
  previewUrl: string;
}

interface ReflectDesignAssetForVariantParameters {
  designIds: string[];
  variantIds: string[];
  useThumbnail?: boolean;
  width?: number;
  height?: number;
}
export default class PreviewRenderer {
  async reflectDesignAssetForVariant({
    designIds,
    variantIds,
    useThumbnail = true,
    width = 500,
    height = 500,
  }: ReflectDesignAssetForVariantParameters): Promise<ReflectDesignAssetForVariantReturnValue[] | { error: string }> {
    try {
      const previewWidth = width || DEFAULT_DIMENSION;
      const previewHeight = height || DEFAULT_DIMENSION;

      const firstUploadedAsset = await extractFirstUploadedAssetByDesignIds(designIds);
      if (!firstUploadedAsset) {
        return {
          error: `No uploaded images found for design ids: ${designIds.join(', ')}`,
        };
      }

      const newImageUrl = firstUploadedAsset.files.find((file) => {
        if (useThumbnail) {
          return file.type === 'preview_thumbnail';
        }
        return file.type === 'preview_default';
      })?.url;

      if (!newImageUrl) {
        return {
          error: 'No valid preview image URL found in the first uploaded asset',
        };
      }

      const results = await Promise.all(
        variantIds.map(async (variantId): Promise<ReflectDesignAssetForVariantReturnValue | { error: string }> => {
          try {
            const variantDesign = await fetchDesign(variantId);
            const newDesignData = getNewDesignWithAppliedImage(
              newImageUrl,
              JSON.parse(variantDesign.structure) as DesignData,
              firstUploadedAsset,
            );
            if (!newDesignData) {
              return { error: `Cannot extract design data for variant ${variantId}` };
            }

            const previewUrl = await generatePreview({
              productUid: newDesignData.product_uid,
              designStructure: newDesignData,
              width: previewWidth,
              height: previewHeight,
              scene: getSceneNameFromProductUid(newDesignData.product_uid),
            });

            return { previewUrl, variantId };
          } catch (error) {
            return { error: `Failed to process variant ${variantId}: ${error.message}` };
          }
        }),
      );

      // Filter out any error results and return the successful ones
      const successfulResults = results.filter(
        (res): res is ReflectDesignAssetForVariantReturnValue => 'previewUrl' in res && 'variantId' in res,
      );

      // If no successful results, return the first error encountered
      if (successfulResults.length === 0) {
        const firstError = results.find((res): res is { error: string } => 'error' in res);
        return firstError || { error: 'Unknown error occurred during processing' };
      }

      return successfulResults;
    } catch (error) {
      return { error: `An unexpected error occurred: ${error.message}` };
    }
  }

  async createDraftAndGetUrl({
    designIds,
    variantId,
  }: {
    designIds: string[];
    variantId: string;
  }): Promise<string | { error: string }> {
    try {
      const firstUploadedAsset = await extractFirstUploadedAssetByDesignIds(designIds);
      if (!firstUploadedAsset) {
        return { error: `No uploaded images found for design ids ${designIds.join(', ')}` };
      }

      const newImageUrl = firstUploadedAsset.files.find((file) => file.type === 'preview_default')?.url;
      if (!newImageUrl) {
        return { error: 'No valid image URL found in the first uploaded asset' };
      }

      const [variants, variantDesign]: [{ [variantId: string]: Variant }, Design] = await Promise.all([
        fetchVariants([variantId]),
        fetchDesign(variantId),
      ]);

      const newDesignData = getNewDesignWithAppliedImage(
        newImageUrl,
        JSON.parse(variantDesign.structure) as DesignData,
        firstUploadedAsset,
      );
      if (!Object.keys(variants).length) {
        return { error: `No data found for variant ${variantId}` };
      }

      if (!newDesignData) {
        return { error: `Could not extract design data for variant ${variantId}` };
      }

      const { gelatoData: ppExtensionData = {} as { customerReferenceId: string | undefined } } =
        window as typeof window & { gelatoData?: { customerReferenceId?: string } };
      const variantData = variants[variantId];

      const customerReferenceId = ppExtensionData.customerReferenceId || getGuestCustomerId();
      const newDraft = await createDraft(
        store,
        newDesignData,
        variantData.externalVariantId,
        variantData.variantId,
        customerReferenceId,
      );

      return `/products/${variantData.productHandle}?designId=${newDraft.designId}&variantId=${variantId}&externalVariantId=${variantData.variantId}`;
    } catch (error) {
      return { error: `An unexpected error occurred: ${error.message}` };
    }
  }

  async generateCanvasPreview({
    imageUrl,
    variantIds,
    usePreflightPreviews = false,
    sizePx = DEFAULT_DIMENSION,
    backgroundColor = 'white',
    generateFirstActiveSpread = true,
    output = 'blob',
  }: {
    imageUrl: string;
    variantIds: string[];
    usePreflightPreviews?: boolean;
    sizePx?: number;
    backgroundColor?: string;
    generateFirstActiveSpread?: boolean;
    output?: SpreadPreviewBlob['type'] | SpreadPreviewDataURL['type'];
  }): Promise<{ variantPreviews: (string | Blob)[]; variantId: string }[] | undefined> {
    const imageDetails = await loadImage(imageUrl, 'anonymous', {
      executor: 'generateCanvasPreview: load image',
    });

    const newGalleryImage: GalleryImage = htmlImageToGalleryImage(imageDetails, imageUrl);
    const newAsset: Asset = htmlImageToDesignAsset(imageDetails, imageUrl);

    const previewPromises = variantIds.map(async (variantId) => {
      const variantPreviews: (string | Blob)[] = [];
      try {
        const variantDesign = await fetchDesign(variantId);
        const { assets } = variantDesign;
        const newDesignStructure = JSON.parse(variantDesign.structure);

        // to mock it locally
        // const assets = mockAssets;
        // const newDesignStructure = mockDesign;

        let newDesignData = getNewDesignWithAppliedImage(imageUrl, cloneDeep(newDesignStructure), newAsset);
        if (!newDesignData) {
          throw new Error(`Can not reflect design data for ${variantId}`);
        }

        newDesignData = formatDesignStructureForCanvasRendering(newDesignData);

        // preload fonts
        const { availableFonts } = store.getState().fonts;
        await loadDesignDataFonts([newDesignData], availableFonts, []).catch((e) => {
          throw new Error(`Can load font for  ${variantId}, ${e}`);
        });

        const { requestRender } = createCanvasRendering();
        const images = [...assetsToGalleryImages(assets), newGalleryImage];
        if (generateFirstActiveSpread) {
          const firstSpreadPreview = await getFirstSpreadPreview(
            newDesignData,
            { dimension: 'both', value: sizePx },
            requestRender,
            images,
            backgroundColor,
            output,
          );

          if (firstSpreadPreview?.type === 'blob' && firstSpreadPreview.blob) {
            variantPreviews.push(firstSpreadPreview.blob);
          } else if (firstSpreadPreview?.type === 'dataURL') {
            variantPreviews.push(firstSpreadPreview.dataURL);
          }
        }

        // if no need to use preflight previews return the first one
        if (!usePreflightPreviews) {
          return {
            variantId,
            variantPreviews,
          };
        }

        // TODO find a way to match scene to the spread index
        const spread = newDesignData.spreads[0];
        const sizeConstraints: SizeConstraints =
          spread.pages[0].height > spread.pages[0].width
            ? { dimension: 'height', value: sizePx }
            : { dimension: 'width', value: sizePx };

        const firstSpreadContent = await createSpreadPreview(
          getDesignKeyFromDesign(newDesignData),
          spread,
          {
            backgroundImage: undefined,
            foregroundImage: undefined,
            images,
            addons: [],
            gridDesigns: [],
          },
          0,
          undefined,
          sizeConstraints,
          requestRender,
          {},
          loadFont,
          false,
          i18n.t,
          {
            showBlanks: true,
            showEmptyImages: true,
            output: 'dataURL',
            format: 'jpeg',
            noShadow: true,
            backgroundColor,
            cropContentArea: true,
            showProduct: false,
          },
        );

        if (!firstSpreadContent) {
          throw new Error(`Can not generate content for first spread of variant ${variantId}`);
        }

        const scenes = await fetchScenes(newDesignStructure.product_uid, sizePx);
        // const scenes = [mockScene] as (PreflightSingleAreaScene | PreflightMultipleAreasScene)[];
        const defaultHtmlImage = await loadImage(firstSpreadContent.dataURL, 'anonymous', {
          executor: 'generateCanvasPreview: load spread preview image',
        });

        const scenePreviewPromises = scenes.map(async (scene) => {
          const dimensions = { width: 0, height: 0 };

          // TODO adjust logic for multiple area scenes
          if (!isSingleAreaScene(scene)) {
            return;
          }

          const loadedAssets: LoadedAssets = {};
          // load scene images and define dimensions
          const sceneAssets = await loadSceneAssets(scene);
          sceneAssets.forEach((asset) => {
            const { name, image } = asset;
            if (image.width > dimensions.width) {
              dimensions.width = image.width;
            }

            if (image.height > dimensions.height) {
              dimensions.height = image.height;
            }

            loadedAssets[name] = image;
          });

          const loadedScene: LoadedScene = {
            assets: loadedAssets,
            config: [
              {
                content: defaultHtmlImage,
                coordinates: scene.config,
              },
            ],
          };

          // generate unique key for render queue
          const uniqueKey = (Math.random() + 1).toString(36).substring(7);
          const scenePreview = await createScenePreview(
            uniqueKey,
            dimensions,
            loadedScene,
            sizeConstraints,
            requestRender,
            { output },
          );

          if (scenePreview) {
            if (scenePreview.type === 'blob' && scenePreview.blob) {
              variantPreviews.push(scenePreview.blob);
            } else if (scenePreview.type === 'dataURL') {
              variantPreviews.push(scenePreview.dataURL);
            }
          }
        });

        await Promise.all(scenePreviewPromises);

        return {
          variantId,
          variantPreviews,
        };
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return {
          variantId,
          variantPreviews,
        };
      }
    });

    return Promise.all(previewPromises);
  }
}

const getFirstSpreadPreview = (
  designData: DesignData,
  sizeConstraints: SizeConstraints,
  requestRender: RequestRenderFn,
  images: GalleryImage[],
  backgroundColor: string,
  output: SpreadPreview['type'],
) => {
  const firstActiveSpreadIndex = designData.spreads.findIndex((spread) => !!spread.pages[0].groups.media?.length);
  return createSpreadPreview(
    getDesignKeyFromDesign(designData),
    designData.spreads[firstActiveSpreadIndex],
    {
      backgroundImage: undefined,
      foregroundImage: undefined,
      images,
      addons: [],
      gridDesigns: [],
    },
    firstActiveSpreadIndex,
    undefined,
    sizeConstraints,
    requestRender,
    {},
    loadFont,
    false,
    i18n.t,
    {
      showBlanks: true,
      showEmptyImages: true,
      output,
      format: 'jpeg',
      noShadow: false,
      backgroundColor,
      showProduct: true,
    },
  );
};
