import { Dimensions, PerspectiveTransform } from 'editor/src/store/design/types';

import { SingleAreaConfig } from 'product-personalizer/src/previewRenderer/types';

const formatPerspectiveTransform = (config: SingleAreaConfig, sceneDimensions: Dimensions): PerspectiveTransform => {
  const widthScale = 1 / sceneDimensions.width;
  const heightScale = 1 / sceneDimensions.height;

  // TODO add angle calculation to shift coordinate more precisely
  // without this value we can see black lines on the area border
  const stretchValue = 2;
  return {
    tl_x: widthScale * (config['top-left'][0] - stretchValue),
    tl_y: heightScale * (config['top-left'][1] - stretchValue),
    tr_x: widthScale * (config['top-right'][0] + stretchValue),
    tr_y: heightScale * (config['top-right'][1] - stretchValue),
    bl_x: widthScale * (config['bottom-left'][0] - stretchValue),
    bl_y: heightScale * (config['bottom-left'][1] + stretchValue),
    br_x: widthScale * (config['bottom-right'][0] + stretchValue),
    br_y: heightScale * (config['bottom-right'][1] + stretchValue),
  };
};

export default formatPerspectiveTransform;
