import { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'editor/src/store/index';
import getSelectedGroupKey from 'editor/src/store/variants/helpers/getSelectedGroupKey';
import groupVariations, { getVariantInfoFromGroup } from 'editor/src/store/variants/helpers/groupVariantsBy';
import { MultiOptions, VariantInfo, VariantsState } from 'editor/src/store/variants/types';

import type { i18n } from 'i18next';

export type Payload = {
  multiOptions: MultiOptions;
  matchingVariantInfos?: VariantInfo[];
  rootState?: RootState;
  i18n?: i18n;
};

const toggleMultiOptionReducer = (state: VariantsState, action: PayloadAction<Payload>) => {
  const { multiOptions, matchingVariantInfos, rootState, i18n } = action.payload;
  state.selectedMultiOptions = multiOptions;

  if (matchingVariantInfos && rootState && i18n) {
    const variationGroups = groupVariations(
      matchingVariantInfos,
      state.product,
      state.designTemplates,
      (variations, groupKey) => getVariantInfoFromGroup(variations, groupKey, state.variationGroups),
      rootState,
      i18n,
    );

    state.variationGroups = variationGroups;
    state.selectedGroupKey = getSelectedGroupKey(state.variationGroups, state.selectedGroupKey);
  }
};

export default toggleMultiOptionReducer;
