import { DesignData, SpreadGroundImage, ProductImage } from 'editor/src/store/design/types';
import {
  MultiOptions,
  ProductVariation,
  SingleOptions,
  VariationProductControl,
} from 'editor/src/store/variants/types';

export interface Scene {
  id: string;
  name: string;
  isPublic: boolean;
  clientId: string;
  tags: string[];
  thumbnailPreview: string;
}

export interface SceneCategory {
  name: string;
  scenes: Scene[];
}

export const enum MockupRole {
  Background = 'background',
  Foreground = 'foreground',
  Placeholder = 'placeholder',
}

export interface MockupVariant {
  productUid: string;
  width: number;
  height: number;
  scene?: string;
}

export interface MockupSize {
  name: string;
  width: number;
  height: number;
  categoryId: string;
}

export interface MockupCategory {
  name: string;
  id: string;
}

export type MockupProductEntry = MockupProductCategory | MockupProductProduct;

export interface MockupProductCategory {
  type: 'category';
  id: string;
  name: string;
  previewUrl: string;
  children: MockupProductEntry[];
}

export interface MockupProductProduct {
  type: 'product';
  id: string;
  name: string;
  previewUrl: string;
  productUid: string;
}

export interface MockupPreviewStore {
  id: string;
  name: string;
  previewUrl: string;
}

export interface MockupPreviewProduct {
  id: string;
  name: string;
  previewUrl: string;
  variantCount: number;
}

export interface MockupPreviewVariant {
  id: string;
  name: string;
  previewUrl: string;
  productUid: string;
  designId: string;
  catalogueId: string;
  scene?: string;
}

export const enum PreviewFormat {
  Jpg = 'jpeg',
  Png = 'png',
}

export interface ProductScene {
  id: string;
  productImage: ProductImage;
  isPremium: boolean;
  canUsePremium: boolean;
}

export interface SaveDraftMessageFormat {
  triggerSideEffect?: boolean;
}

export interface ProductSceneCategory {
  name: string;
  scenes: ProductScene[];
}

export interface MockupState {
  scenes: {
    categories: SceneCategory[];
    structure: { [sceneId: string]: DesignData };
    selectedSceneId: string | undefined;
  };
  mockup: {
    sizes: MockupSize[];
    categories: MockupCategory[];
    structure: { [size: string]: DesignData };
  };
  productPlaceholder: {
    productImages: { [id: string]: ProductImage };
    variantProductImages: { [variantId: string]: ProductImage };
    activeProductUid: string;
    variants: { [productUid: string]: MockupVariant }; // we will fetch them one by one
    variations: ProductVariation[];
    controls: VariationProductControl[];
    singleOptions: SingleOptions;
    multiOptions: MultiOptions;
    sceneImages: {
      [productUid: string]:
        | {
            backgrounds: SpreadGroundImage[];
            foregrounds: SpreadGroundImage[];
          }
        | undefined;
    };
  };
  scenePlaceholder: {
    sceneCategories: { [productUid: string]: ProductSceneCategory[] };
    scenes: { [sceneId: string]: ProductScene };
    productCategories: MockupProductCategory[];
  };
  preview: {
    downloading: boolean;
    appliedVariant: MockupPreviewVariant | undefined;
    stores: { hasMore: boolean; entries: MockupPreviewStore[] | undefined };
    products: {
      [storeId: string]: {
        hasMore: boolean;
        entries: MockupPreviewProduct[] | undefined;
      };
    };
    variants: {
      [storeId: string]: {
        hasMore: boolean;
        entries: MockupPreviewVariant[] | undefined;
      };
    };
  };
}
