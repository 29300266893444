import { CropOrigin, Dimensions, MediaImage } from 'editor/src/store/design/types';

import getCenteredImagePosition from 'editor/src/util/2d/getCenteredImagePosition';
import getPointPositionRotatedOnPoint from 'editor/src/util/getPointPositionRotatedOnPoint';
import limitPrecision from 'editor/src/util/limitPrecision';

import { Bbox, getRectangleBoundingBox, Mode } from './utils';

/**
 * Returns the crop position removing the rotation.
 */
export function getUnrotatedCropPosition(
  px: number,
  py: number,
  bbox: Bbox,
  elementDimensions: Dimensions,
  angle: number,
) {
  // the element frame and it's rotated bounding box have a different size. They are centered. We need to remove this offset
  const dX = (elementDimensions.width - bbox.width) / 2;
  const dY = (elementDimensions.height - bbox.height) / 2;
  return getPointPositionRotatedOnPoint(px - dX, py - dY, bbox.width / 2, bbox.height / 2, -angle);
}

/**
 * Returns the crop position with the rotation applied
 */
export function getRotatedCropPosition(
  px: number,
  py: number,
  bbox: Bbox,
  elementDimensions: Dimensions,
  angle: number,
) {
  const rotatedPoint = getPointPositionRotatedOnPoint(px, py, bbox.width / 2, bbox.height / 2, angle);
  // the element frame and it's rotated bounding box have a different size. They are centered. We need to remove this offset
  rotatedPoint[0] += (elementDimensions.width - bbox.width) / 2;
  rotatedPoint[1] += (elementDimensions.height - bbox.height) / 2;
  return rotatedPoint;
}

/**
 * This method calculates the position of the cropped image in its final bounding box, it will try to keep the center of the source frame
 * at the same position in the final frame.
 * The idea is simple:
 *  - we un-rotate the crop image position
 *  - we compute the new position value in the final frame
 *  - we validate with min/max
 *  - we re-rotate it
 */
export function getAdjustedCropPosition(
  sourceCrop: CropOrigin,
  sourceBbox: Bbox,
  destCrop: { pw: number; ph: number; width: number; height: number },
  destBbox: Bbox,
  angle: number,
) {
  const sourceUnrotated = getUnrotatedCropPosition(sourceCrop.px, sourceCrop.py, sourceBbox, sourceCrop, angle);

  // the position of the center of the bbox relative to the image before and after should be the same
  // the center of the image position is:
  // - x: -px + bbox.width / 2 // px unrotated image.px position
  // - y: -py + bbox.height / 2 // py unrotated image.px position
  // the position of the center of the image relative to the bbox is;
  // the center of the image position is:
  // - x: (-px + bbox.width / 2) / image.pw
  // - y: (-py + bbox.height / 2) / image.ph
  // the center of the image relative to the image should stay the same after resizing:
  // => (-dest.px + dest.bbox.width / 2) / dest.pw = (-souce.px + source.bbox.width / 2) / source.pw
  // => dest.px = dest.bbox.width / 2 - (-souce.px + source.bbox.width / 2) * (dest.pw / source.pw)
  // with dest.px & source.px, the unrotated image offset

  const adjustedPx = destBbox.width / 2 - (-sourceUnrotated[0] + sourceBbox.width / 2) * (destCrop.pw / sourceCrop.pw);
  const adjustedPy =
    destBbox.height / 2 - (-sourceUnrotated[1] + sourceBbox.height / 2) * (destCrop.ph / sourceCrop.ph);

  // make sure the position is within the image size minus the rotated frame
  const pxBoxed = Math.min(0, Math.max(destBbox.width - destCrop.pw, adjustedPx));
  const pyBoxed = Math.min(0, Math.max(destBbox.height - destCrop.ph, adjustedPy));

  const cropPos = getRotatedCropPosition(pxBoxed, pyBoxed, destBbox, destCrop, angle);
  cropPos[0] = limitPrecision(cropPos[0]);
  cropPos[1] = limitPrecision(cropPos[1]);
  return cropPos;
}

function getAdjustedCropDimensions(sourceCrop: { pw: number; ph: number }, destBbox: Bbox, scale: number) {
  const pw = Math.max(destBbox.width, sourceCrop.pw * scale);
  const ph = Math.max(destBbox.height, sourceCrop.ph * scale);
  return [limitPrecision(pw), limitPrecision(ph)];
}

/**
 * Update the image cropping based on the source cropping and the dest element dimensions
 */
function updateImageElement(sourceElement: MediaImage, destElement: MediaImage, mode: Mode, ignoreCropOrigin = false) {
  if (destElement.imageId) {
    if (mode === 'reset') {
      const { px, py, pw, ph } = getCenteredImagePosition(destElement, {
        width: sourceElement.pw,
        height: sourceElement.ph,
      });
      destElement.px = destElement.pattern ? 0 : px;
      destElement.py = destElement.pattern ? 0 : py;
      destElement.pw = pw;
      destElement.ph = ph;
      destElement.pr = 0;
      destElement.cropOrigin = undefined;
      return;
    }

    const cropOriginElement = (!ignoreCropOrigin && sourceElement.cropOrigin) || {
      pw: limitPrecision(sourceElement.pw),
      ph: limitPrecision(sourceElement.ph),
      px: limitPrecision(sourceElement.px),
      py: limitPrecision(sourceElement.py),
      width: limitPrecision(sourceElement.width),
      height: limitPrecision(sourceElement.height),
    };
    destElement.cropOrigin = cropOriginElement;
    const sourceBbox = getRectangleBoundingBox(
      0,
      0,
      cropOriginElement.width,
      cropOriginElement.height,
      sourceElement.pr,
    );
    const destBbox = getRectangleBoundingBox(0, 0, destElement.width, destElement.height, sourceElement.pr);

    const scale = Math.max(destBbox.width / cropOriginElement.pw, destBbox.height / cropOriginElement.ph);
    const [pw, ph] = getAdjustedCropDimensions(cropOriginElement, destBbox, scale);
    destElement.pw = pw;
    destElement.ph = ph;
    destElement.pr = sourceElement.pr;

    if (destElement.pattern) {
      destElement.px = 0;
      destElement.py = 0;
    } else if (
      limitPrecision(cropOriginElement.width / cropOriginElement.height) ===
      limitPrecision(destElement.width / destElement.height)
    ) {
      // same aspect ratio
      destElement.px = limitPrecision(cropOriginElement.px * scale);
      destElement.py = limitPrecision(cropOriginElement.py * scale);
    } else {
      // different aspect ratio
      const [px, py] = getAdjustedCropPosition(cropOriginElement, sourceBbox, destElement, destBbox, sourceElement.pr);
      destElement.px = px;
      destElement.py = py;
    }
  } else {
    destElement.px = 0;
    destElement.py = 0;
    destElement.pw = destElement.width;
    destElement.ph = destElement.height;
    destElement.pr = 0;
  }
}

export default updateImageElement;
