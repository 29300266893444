/* eslint-disable */
import { captureException } from '@sentry/react';
import { fabric } from 'fabric';

import { Dimensions } from 'editor/src/store/design/types';

import FabricImage from 'editor/src/fabric/FabricImage';
import { LoadedScene } from 'product-personalizer/src/previewRenderer/types';

import PerspectiveTransformFilter from 'editor/src/component/EditorArea/fabricFilters/PerspectiveTransformFilter';
import { RequestRenderFn, SpreadPreviewBlob, SpreadPreviewDataURL } from 'editor/src/component/SpreadPreview';
import {
  getDimensionsFromConstraints,
  getPreviewForFormat, PreviewOutput,
  SizeConstraints,
  SpreadPreviewCanvas
} from 'editor/src/component/SpreadPreview/createSpreadPreview';
import formatPerspectiveTransform from 'product-personalizer/src/previewRenderer/utils/formatPerspectiveTransform';

export interface ScenePreviewOptions<Type extends PreviewOutput = 'dataURL'> {
  output?: Type;
  format?: 'jpeg' | 'png';
  enableRetinaScaling?: boolean;
  backgroundColor?: string;
}

function createScenePreview<
  Output extends Type extends 'blob'
    ? SpreadPreviewBlob
    : Type extends 'dataURL'
      ? SpreadPreviewDataURL
      : Type extends 'canvas'
        ? SpreadPreviewCanvas
        : never,
  Type extends PreviewOutput = 'dataURL',
>(
  sceneKey: string,
  dimensions: Dimensions,
  loadedScene: LoadedScene,
  sizeContstraint: SizeConstraints,
  requestRender: RequestRenderFn,
  options?: ScenePreviewOptions<Type>,
): Promise<Output | null> {
  const format = options?.format || 'jpeg';
  const outputFormat = options?.output ?? 'dataURL';
  const enableRetinaScaling = options?.enableRetinaScaling ?? true;
  const { width, height } = getDimensionsFromConstraints(sizeContstraint, dimensions.width, dimensions.height);
  const scaleX = width / dimensions.width;
  const scaleY = height / dimensions.height;

  return requestRender(async (fabricCanvas) => {
    // reset viewport transform as it may be changed from previous render
    fabricCanvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    if (fabricCanvas.getWidth() !== width || fabricCanvas.getHeight() !== height) {
      fabricCanvas.setDimensions({ width, height });
    }

    fabricCanvas.backgroundColor = options?.backgroundColor || 'transparent';
    loadedScene.config.forEach(({ coordinates, content }) => {
      const spreadPreviewDefault = new FabricImage(content, {
        crossOrigin: 'anonymous',
        objectCaching: false,
        angle: 0,
        scaleX: width / content.width,
        scaleY: height / content.height,
        left: 0,
        top: 0,
        zIndex: 3,
      });

      spreadPreviewDefault.applyFilters([
        new PerspectiveTransformFilter({
          transform: formatPerspectiveTransform(coordinates, dimensions),
        }),
      ]);

      fabricCanvas.add(spreadPreviewDefault);
    });

    if (loadedScene.assets.foreground) {
      const fabricImg = new FabricImage(loadedScene.assets.foreground, {
        crossOrigin: 'anonymous',
        objectCaching: false,
        angle: 0,
        scaleX,
        scaleY,
        left: 0,
        top: 0,
        zIndex: 3,
      });
      fabricCanvas.add(fabricImg);
    }

    try {
      fabricCanvas.renderAll();

      const options: fabric.IDataURLOptions = {
        format,
        quality: 0.6,
        enableRetinaScaling,
      };

      const outputWidth = enableRetinaScaling ? width * window.devicePixelRatio : width;
      const outputHeight = enableRetinaScaling ? height * window.devicePixelRatio : height;
      // TODO add dispose?
      return await getPreviewForFormat<Output>(fabricCanvas, outputFormat, outputWidth, outputHeight, format, options);
    } catch (e) {
      captureException(new Error(e || 'ScenePreview: failed to generate a preview'));
    }

    return null;
  }, 'uniqueDesign');
}

export default createScenePreview;
