import { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'editor/src/store/index';
import getSelectedGroupKey from 'editor/src/store/variants/helpers/getSelectedGroupKey';
import groupVariations, { getVariantInfoFromGroup } from 'editor/src/store/variants/helpers/groupVariantsBy';
import { ExternalOptions, VariantInfo, VariantsState } from 'editor/src/store/variants/types';

import type { i18n } from 'i18next';

export type Payload = {
  externalOptions: ExternalOptions;
  matchingVariantInfos: VariantInfo[];
  rootState: RootState;
  i18n: i18n;
};

const toggleExternalOptionReducer = (state: VariantsState, action: PayloadAction<Payload>) => {
  const { externalOptions, matchingVariantInfos, rootState, i18n } = action.payload;
  state.selectedExternalOptions = externalOptions;
  const variationGroups = groupVariations(
    matchingVariantInfos,
    state.product,
    state.designTemplates,
    (variations, groupKey) => getVariantInfoFromGroup(variations, groupKey, state.variationGroups),
    rootState,
    i18n,
  );

  state.variationGroups = variationGroups;
  state.selectedGroupKey = getSelectedGroupKey(state.variationGroups, state.selectedGroupKey);
};

export default toggleExternalOptionReducer;
