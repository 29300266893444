enum zIndex {
  BACKGROUND = 1, // background image
  PERSPECTIVE_LINE = 1.5, // perspective line
  BACKGROUND_COLOR = 10, // content area / white space

  MIRROR = 900, // mirrored media elements
  MEDIA = 1000, // all the media elements
  WARNING_WATERMARK = 1100, // warning watermark
  BLANK = 1500, // blanks elements
  FADE = 2000, // side fades / dimming areas used for canvas
  HOLE = 4500, // holes
  BLEED = 5000, // dotted/dashed lines
  CONTENT = 6000, // content borders used in apparels/tote back to show the content area on top of a background image
  DIECUT = 7000, // die cute => dash lines
  ROLLER_STRIPES = 8000, // wallpaper
  FOLDING_LINE = 8001, // dash lines
  GRID_LINE = 8002, //
  SAFE_AREA = 1050, // safe area on the canvas

  FOREGROUND = 9000, // foreground image

  // UI
  DROP_BOX = 20000,
  HOVER_BOX = 20001,
  SNAP_LINE = 20002,
}

export default zIndex;
