import showLoaderOperation from 'editor/src/store/app/operation/showLoaderOperation';
import { LoaderType } from 'editor/src/store/app/types';
import type { DesignData } from 'editor/src/store/design/types';
import applyDesign from 'editor/src/store/design/util/applyDesign';
import addUploadedImagesOperation from 'editor/src/store/gallery/operation/addUploadedImagesOperation';
import { UploadedImageData } from 'editor/src/store/gallery/types';

import { PreflightMultipleAreasScene, PreflightSingleAreaScene } from 'product-personalizer/src/previewRenderer/types';
import store from 'product-personalizer/src/store';
import { Design } from 'product-personalizer/src/types/design';
import { PricesResponse } from 'product-personalizer/src/types/prices';
import { Variant } from 'product-personalizer/src/types/variant';

import { setDesignIsLoadingAction, setProductImagesAction } from '../store/productPersonalizer/slice';

import getUploadedImageDataFromAssets from './assets';
import getCustomerReferenceId from './getCustomerReferenceId';
import loadResources from './loadResources';
import setDesignDataPPOperation from './setDesignDataPPOperation';

const designVariantCache: Record<string, Design> = {};
const designIdCache: Record<string, Design> = {};
const variantsCache: Record<string, { [shopifyVariantId: string]: Variant }> = {};

export async function fetchDesign(variantId: string) {
  const cachedDesign = designVariantCache[variantId];
  if (cachedDesign) {
    return cachedDesign;
  }

  const response = await fetch(`${LOCAL_ENV.ecomProxyEndpoint}/variants/${variantId}/design`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  designVariantCache[variantId] = await response.json();
  return designVariantCache[variantId];
}

export async function fetchVariants(variantIds: string[]): Promise<{ [shopifyVariantId: string]: Variant }> {
  // TODO find the way to cache it smarter
  const cacheKey = variantIds.join(',');
  const cachedVariants = variantsCache[cacheKey];
  if (cachedVariants) {
    return cachedVariants;
  }

  const queryString = prepareQueryStringFromArray('externalIds', variantIds);
  const response = await fetch(`${LOCAL_ENV.ecomProxyEndpoint}/variants?${queryString}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  variantsCache[variantIds.join(',')] = await response.json();
  return variantsCache[cacheKey];
}

export async function fetchDesignFromId(designId: string) {
  const cachedDesign = designIdCache[designId];
  if (cachedDesign) {
    return cachedDesign;
  }

  const customerReferenceId = getCustomerReferenceId();
  const response = await fetch(
    `${LOCAL_ENV.ecomProxyEndpoint}/designs/${designId}?customerReferenceId=${customerReferenceId}`,
  );
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  designIdCache[designId] = await response.json();
  return designIdCache[designId];
}

export async function fetchShippingPrices(
  currency: string,
  country: string,
  variantId: string,
  quantity: string,
): Promise<PricesResponse> {
  const { structure: structureJSON } = await fetchDesign(variantId);
  const structure = JSON.parse(structureJSON);
  const productUid = structure.product_uid;
  const pageCount = structure.page_count;
  const products = [{ productUid, pageCount, quantities: [quantity] }];

  const searchParams = new URLSearchParams({
    currency,
    country,
  });

  products.forEach((product, index) => {
    if (product.pageCount) {
      searchParams.append(`products[${index}][pageCount]`, product.pageCount.toString());
    }

    searchParams.append(`products[${index}][productUid]`, product.productUid);

    product.quantities.forEach((quantity, quantityIndex) => {
      searchParams.append(`products[${index}][quantities][${quantityIndex}]`, quantity.toString());
    });
  });

  const response = await fetch(`${LOCAL_ENV.ecomProxyEndpoint}/shipping-prices?${searchParams.toString()}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
}

export async function fetchProductPreviews(
  variantId: string,
  width: number,
  height: number,
  customizationId?: string,
  scene?: string,
): Promise<string> {
  const { structure: structureJSON } = customizationId
    ? await fetchDesignFromId(customizationId)
    : await fetchDesign(variantId);
  const designStructure = JSON.parse(structureJSON);
  const productUid = designStructure.product_uid;

  // TODO get scenes list by productUid (implement after EIN-6981)

  // get scene preview
  const previewUrl = await generatePreview({
    productUid,
    designStructure,
    width,
    height,
    scene, // TODO get scene previews after implementing EIN-6981
  });

  return previewUrl;
}

export const loadDesign = async (variantId: string, designData?: DesignData, designId?: string) => {
  let structure = designData ? (JSON.parse(JSON.stringify(designData)) as DesignData) : undefined;
  let assetsToLoad: Design['assets'] = [];
  let isMissingAssets = false;

  store.dispatch(setDesignIsLoadingAction(true));

  if (designId) {
    const data = await fetchDesignFromId(designId);
    structure = JSON.parse(data.structure) as DesignData;
    assetsToLoad = data.assets as Design['assets'];
  } else if (!structure) {
    const data = await fetchDesign(variantId);
    structure = JSON.parse(data.structure) as DesignData;
    assetsToLoad = data.assets as Design['assets'];
  } else {
    const gallery = store.getState().gallery.images;
    isMissingAssets = structure.spreads.some((spread) =>
      spread.pages[0].groups.media?.some((element) => {
        if (element.type === 'image') {
          return !gallery.find((image) => image.id === element.imageId);
        }
        return false;
      }),
    );
  }

  const state = store.getState();

  const { designData: stateDesignData, originalDesignData } = state.design;

  if (stateDesignData) {
    structure = applyDesign(stateDesignData, originalDesignData, structure, false, undefined);
  }

  structure.spreads = structure.spreads.filter((spread) => spread.pages.length > 0);

  void store.dispatch(setDesignDataPPOperation(structure));

  if (isMissingAssets) {
    const data = await fetchDesign(variantId);
    assetsToLoad = data.assets as Design['assets'];
  }

  let imageData: UploadedImageData[] = [];
  if (assetsToLoad) {
    imageData = getUploadedImageDataFromAssets(assetsToLoad);
    await store.dispatch(addUploadedImagesOperation(imageData));
  }

  void loadResources(structure).then(() => {
    store.dispatch(setDesignIsLoadingAction(false));
  });
};

export async function fetchAndLoadDesign(variantId: string, designData?: DesignData, designId?: string) {
  store.dispatch(showLoaderOperation(LoaderType.ProductPersonalizerPreview));
  await loadDesign(variantId, designData, designId);
}

export async function generatePreview({
  productUid,
  designStructure,
  width,
  height,
  scene,
}: {
  productUid: string;
  width: number;
  height: number;
  scene: string | undefined;
  designStructure: DesignData;
}) {
  const params = new URLSearchParams();
  params.append('productUid', productUid);
  params.append('width', width.toString());
  params.append('height', height.toString());
  if (scene) {
    params.append('scene', scene);
  }
  // TODO change the login on passing the scene
  const notEmptySpread = designStructure.spreads.find((spread) => spread.pages.length);
  if (notEmptySpread?.pages[0].page_nr) {
    params.append('pageNumber', notEmptySpread?.pages[0].page_nr.toString());
  }

  const res = await fetch(`${LOCAL_ENV.ecomProxyEndpoint}/product/preview?${params.toString()}`, {
    method: 'POST',
    body: JSON.stringify({
      designStructure: JSON.stringify(designStructure),
    }),
  });

  const data = (await res.json()) as { url: string };
  return data.url;
}

export async function fetchScenes(
  productUid: string,
  sizePx: number | undefined,
): Promise<Array<PreflightSingleAreaScene | PreflightMultipleAreasScene>> {
  const searchParams = new URLSearchParams({
    productUid,
  });

  if (sizePx) {
    searchParams.append('sizePx', sizePx.toString());
  }

  const response = await fetch(`${LOCAL_ENV.ecomProxyEndpoint}/product/preview/assets?${searchParams.toString()}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const { scenes } = await response.json();
  return scenes;
}

export function setProductImages(images: string[]) {
  store.dispatch(setProductImagesAction(images));
}

function prepareQueryStringFromArray(paramName: string, inputParams: string[]) {
  const params = new URLSearchParams();
  inputParams.forEach((item, index) => params.append(`${paramName}[${index}]`, item));
  return params.toString();
}
