import { PayloadAction } from '@reduxjs/toolkit';

import { MultipleProductItem, VariantsState } from 'editor/src/store/variants/types';

import { ProductDataPayload } from './setProductDataReducer';

export type ProductsDataPayload = {
  selectedProductId: string;
  products: {
    [productUid: string]: ProductDataPayload;
  };
};

const updateMultipleProductItemReducer = (
  state: VariantsState,
  action: PayloadAction<{
    productId: string;
    data: Partial<MultipleProductItem>;
  }>,
) => {
  const { productId, data } = action.payload;
  if (state.products) {
    state.products[productId] = { ...state.products[productId], ...data };
  }
};

export default updateMultipleProductItemReducer;
