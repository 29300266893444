import cn from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import openPopupOperation from 'editor/src/store/app/module/popup/operation/openPopupOperation';
import deleteDraftOperation from 'product-personalizer/src/store/drafts/operations/deleteDraftOperation';
import loadDraftsOperation from 'product-personalizer/src/store/drafts/operations/loadDraftsOperation';
import { Draft, VariantInfo, toggleSidebarAction } from 'product-personalizer/src/store/drafts/slice';
import { useDispatch, useSelector } from 'product-personalizer/src/store/hooks';

import IconCross from 'editor/src/component/Icon/IconCross';
import { PopupName } from 'editor/src/component/Popup/popups';

import DraftItem from './DraftItem';
import EmptyDraft from './EmptyDraft';

import styles from './index.module.scss';

function DraftSidebar() {
  const open = useSelector((state) => state.drafts.sidebarOpen);
  const drafts = useSelector((state) => state.drafts.drafts);
  const variants = useSelector((state) => state.drafts.variants);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onEdit = useCallback((draft: Draft, variant: VariantInfo) => {
    window.dispatchEvent(
      new CustomEvent('pp-edit-draft', {
        detail: {
          designId: draft.designId,
          productHandle: variant.productHandle,
          variantId: variant.variantId,
          externalVariantId: variant.externalVariantId,
        },
      }),
    );
    dispatch(toggleSidebarAction());
  }, []);

  const requestConfirm = useCallback((): Promise<boolean> => {
    return new Promise((resolve) => {
      dispatch(
        openPopupOperation(PopupName.LOCAL_CONFIRMATION_POPUP, {
          popupTitle: t('Would you like to delete this product?'),
          textLines: [t('If you delete it permanently, it won’t be accessible anymore.')],
          onCancel: () => resolve(false),
          options: [
            { title: t('Cancel') },
            {
              title: t('Delete permanently'),
              onConfirm: () => resolve(true),
            },
          ],
          hideTitleIcon: true,
        }),
      );
    });
  }, []);

  const onDelete = useCallback(async (draft: Draft) => {
    const confirmed = await requestConfirm();
    if (confirmed) {
      void dispatch(deleteDraftOperation(draft.designId));
    }
  }, []);

  useEffect(() => {
    const missingVariantIds = new Set();
    drafts.forEach((draft) => {
      if ((!draft.variantId || !variants[draft.variantId]) && !variants[draft.externalVariantId]) {
        missingVariantIds.add(draft.variantId);
      }
    });
    if (missingVariantIds.size) {
      void dispatch(loadDraftsOperation(drafts));
    }
  }, [drafts]);

  return (
    <div className={cn(styles.DraftSidebar, { [styles.open]: open }, 'cy-draft-sidebar')}>
      <div className={styles.header}>
        {t('My drafts')}
        <div
          className={cn(styles.closeBtn, 'cy-draft-sidebar-close')}
          aria-label={t('editor-close')}
          onClick={() => dispatch(toggleSidebarAction())}
        >
          <IconCross />
        </div>
      </div>
      {drafts.length === 0 && <EmptyDraft />}
      {drafts.map((draft) => {
        const variant = (draft.variantId && variants[draft.variantId]) || variants[draft.externalVariantId];
        if (!variant) {
          return null;
        }

        return <DraftItem key={draft.designId} draft={draft} variant={variant} onDelete={onDelete} onEdit={onEdit} />;
      })}
    </div>
  );
}

export default React.memo(DraftSidebar);
