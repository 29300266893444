import { generateGridAsSVG, generatorUtils, designs } from '@gelatoas/design-editor-calendar';
import cloneDeep from 'lodash/cloneDeep';

import {
  DesignData,
  ExportedDesignData,
  MediaAddon,
  MediaElement,
  MediaImage,
  MediaLine,
  Page,
  SpreadGroup,
} from 'editor/src/store/design/types';
import { Addon } from 'editor/src/store/editorModules/addons/types';
import { ExportedWarning } from 'editor/src/store/editorModules/warnings/types';
import { GalleryImage, ImageState } from 'editor/src/store/gallery/types';
import shouldDigitizeSpread from 'editor/src/store/utils/shouldDigitizeSpread';

import createGridHash from 'editor/src/util/design/createGridHash';
import { getCalendarEventsPerMonth } from 'editor/src/util/layouts/getCalendarEventsPerMonth';
import limitPrecision from 'editor/src/util/limitPrecision';
import { elementUuids } from 'editor/src/util/uuids';

import { PluginName } from '../../plugins/types';

import getCurrentGridDesign from './getCurrentGridDesign';
import { getElementURL } from './getImageElementUrl';
import { getSpreadHeightFromSpread } from './getSpreadHeight';
import getSpreadWidthFromSpread from './getSpreadWidthFromSpread';

const getDesignDataForExport = (
  designData: DesignData,
  galleryImages: GalleryImage[],
  addons: Addon[],
  warnings: ExportedWarning[],
) => {
  const exportDesign: ExportedDesignData = cloneDeep(designData);
  let usePersonalization = !!exportDesign.personalizationSettings?.allowAddElements;

  if (designData.calendar?.allowPersonalization) {
    usePersonalization = true;
  }

  if (designData.spread_groups) {
    designData.spread_groups.forEach((spreadGroup) => applySpreadGroupToDesign(exportDesign, spreadGroup));
  }

  exportDesign.spreads.forEach((spread) => {
    if (spread.conditionGroup && Object.keys(spread.conditionGroup.conditions).length > 0) {
      usePersonalization = true;
    }

    spread.pages[0].groups.media?.forEach((element) => {
      if (!element.personalizationLocked) {
        usePersonalization = true;
      }

      if (element.type === 'line') {
        applyLineElementForExport(element);
        return;
      }

      if (element.type === 'image' || element.type === 'addon') {
        applyImageElementForExport(element, galleryImages, addons, shouldDigitizeSpread(spread));
      }

      // legacy text elements have an html property
      if (element.type === 'text' && element.html) {
        delete element.html;
      }

      if (element.type === 'text') {
        element.rendered_with = 'opentype.js';
        delete (element as any).path; // legacy text elements have a path property
      }

      if (element.type === 'grid') {
        const monthEvents = getCalendarEventsPerMonth(
          element.grid.gridDate,
          element.grid.locale,
          exportDesign.calendar,
        );

        if (!element.gridHash || element.gridHash !== createGridHash(element.grid, monthEvents)) {
          const spreadWidth = getSpreadWidthFromSpread(spread);
          const gridDesign = getCurrentGridDesign(designs.gridDesigns, spread);

          element.content = generateGridAsSVG(
            gridDesign,
            element.grid.gridDate,
            element.grid.locale,
            element.width,
            element.height,
            monthEvents,
            {
              d2f: generatorUtils.getToSizeFn(gridDesign, spreadWidth),
              firstDayOfWeek: element.grid.firstDayOfWeek,
              hideWeekNum: element.grid.hideWeekNum,
            },
          );
          element.gridHash = createGridHash(element.grid, monthEvents);
        }
      }

      element.x = limitPrecision(element.x);
      element.y = limitPrecision(element.y);
      element.width = limitPrecision(element.width);
      element.height = limitPrecision(element.height);
      element.r = limitPrecision(element.r);
    });

    // we duplicate all the elements of the first page to the other pages, adjusting for page position.
    spread.pages.forEach((page, pageIndex) => {
      if (pageIndex === 0) {
        return;
      }
      page.groups.media = spread.pages[0].groups.media?.map((element) => getAdjustedElementByPage(element, page));
    });
  });

  if (warnings) {
    exportDesign.warnings = warnings;
  }

  // product that were published before this plugin existed will not have it when an order is placed
  if (usePersonalization && !exportDesign.plugins?.includes(PluginName.personalizationStudio)) {
    exportDesign.plugins = [...(exportDesign.plugins || []), PluginName.personalizationStudio];
  }

  if (exportDesign.lifecycle_metadata) {
    const lastUpdated = new Date(exportDesign.lifecycle_metadata.updated_at);
    const now = new Date();
    exportDesign.lifecycle_metadata.edit_duration_seconds += Math.floor((now.getTime() - lastUpdated.getTime()) / 1000);
    exportDesign.lifecycle_metadata.updated_at = now.toISOString();
  }

  exportDesign.is_personalizable = usePersonalization || undefined;

  return exportDesign;
};

const applySpreadGroupToDesign = (exportDesign: DesignData, spreadGroup: SpreadGroup): void => {
  const firstSpread = exportDesign.spreads[spreadGroup.spreadIndexes[0]];
  const spreadGroupPosition = spreadGroup.position;
  const spreadWidth = spreadGroupPosition === 'horizontal' ? getSpreadWidthFromSpread(firstSpread) : 0;
  const spreadHeight = spreadGroupPosition === 'vertical' ? getSpreadHeightFromSpread(firstSpread) : 0;

  for (let i = 1; i < spreadGroup.spreadIndexes.length; i += 1) {
    const media = firstSpread?.pages[0].groups.media?.map((element) => {
      const y = spreadHeight * i;
      const x = spreadWidth * i;
      const uuid = elementUuids.generate();
      if (element.type === 'line') {
        return {
          ...element,
          x1: element.x1 + x,
          y1: element.y1 + y,
          x2: element.x2 + x,
          y2: element.y2 + y,
          uuid,
        };
      }

      return {
        ...element,
        y: y + element.y,
        x: x + element.x,
        uuid,
      };
    });

    exportDesign.spreads[spreadGroup.spreadIndexes[i]].pages[0].groups.media = media;
  }
};

const applyLineElementForExport = (element: MediaLine) => {
  element.x1 = limitPrecision(element.x1);
  element.x2 = limitPrecision(element.x2);
  element.y1 = limitPrecision(element.y1);
  element.y2 = limitPrecision(element.y2);
};

const applyImageElementForExport = (
  element: MediaImage | MediaAddon,
  galleryImages: GalleryImage[],
  addons: Addon[],
  quantized: boolean,
) => {
  element.px = limitPrecision(element.px);
  element.py = limitPrecision(element.py);
  element.pw = limitPrecision(element.pw);
  element.ph = limitPrecision(element.ph);
  element.pr = limitPrecision(element.pr);

  if (!element.url) {
    element.url = getElementURL(element, galleryImages, addons, false, false, quantized).imageUrl;
  }

  if (element.type === 'image') {
    if (element.imageId) {
      const asset = galleryImages.find((image) => image.id === element.imageId);
      element.uploading = (asset && asset.state !== ImageState.UPLOADED) || element.loading || undefined;
      if (!asset || asset.state !== ImageState.UPLOADED) {
        element.imageId = '';
        element.url = '';
      } else if (asset.state === ImageState.UPLOADED && asset.url) {
        // After editing the colors, image with color overrides should be shown
        // Show quantized image if it was applied
        // After changing the variant, the preview should show the custom image
        element.url = element.colorOverrides?.url || (quantized ? asset.quantizedUrl : asset.url);
      }
    } else {
      element.url = '';
    }
  }
};

const getAdjustedElementByPage = (element: MediaElement, page: Page) => {
  if (element.type === 'line') {
    return {
      ...element,
      x1: element.x1 - page.x,
      y1: element.y1 - page.y,
      x2: element.x2 - page.x,
      y2: element.y2 - page.y,
    };
  }

  return {
    ...element,
    x: element.x - page.x,
    y: element.y - page.y,
  };
};

export default getDesignDataForExport;
