export const GUEST_UID_KEY = 'guest-uid';

export const getGuestCustomerId = (): string => {
  let guestUid = getStoredGuestUid();
  if (!guestUid) {
    guestUid = generateUniqueGuestId();
    window.localStorage.setItem(GUEST_UID_KEY, guestUid);
  }

  return guestUid;
};

export function getStoredGuestUid() {
  return window.localStorage.getItem(GUEST_UID_KEY);
}

export function clearGuestCustomerId(): void {
  window.localStorage.removeItem(GUEST_UID_KEY);
}

const generateUniqueGuestId = (length = 10) => {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let id = '';
  for (let i = 0; i < length; i += 1) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters[randomIndex];
  }

  return Date.now().toString(16) + id;
};

export const updateGuestAssetsWithUserId = async (oldCustomerReferenceId: string, newCustomerReferenceId: string) => {
  await fetch(`${LOCAL_ENV.ecomProxyEndpoint}/assets:update-customer-reference-id`, {
    method: 'post',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      oldCustomerReferenceId,
      newCustomerReferenceId,
    }),
  });
};

export const updateGuestDraftsWithUserId = async (oldCustomerReferenceId: string, newCustomerReferenceId: string) => {
  await fetch(`${LOCAL_ENV.ecomProxyEndpoint}/designs:update-customer-reference-id`, {
    method: 'post',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      oldCustomerReferenceId,
      newCustomerReferenceId,
    }),
  });
};
