import { captureException } from '@sentry/react';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import type { AsyncThunk } from 'product-personalizer/src/store/hooks';

import { VariantInfo, deleteDraftAction, setVariantsAction, Draft, setDraftsAction } from '../slice';

const loadDraftsOperation =
  (customerDrafts: Draft[]): AsyncThunk =>
  async (dispatch, getState) => {
    let drafts: Draft[] = [];
    if (customerDrafts.length) {
      drafts = customerDrafts;
    }

    let { variants } = getState().drafts;
    if (!drafts.length) {
      return;
    }

    try {
      const url = new window.URL(`${LOCAL_ENV.ecomProxyEndpoint}/variants`, window.location.origin);
      let gelatoIndex = 0;
      let externalIndex = 0;
      const seenVariants = new Set<string>(Object.keys(variants));
      drafts.forEach((draft) => {
        if (!seenVariants.has(draft.externalVariantId)) {
          seenVariants.add(draft.externalVariantId);
          url.searchParams.set(`externalIds[${externalIndex}]`, draft.externalVariantId);
          externalIndex += 1;
        } else if (draft.variantId && !seenVariants.has(draft.variantId)) {
          seenVariants.add(draft.variantId);
          url.searchParams.set(`ids[${gelatoIndex}]`, draft.variantId);
          gelatoIndex += 1;
        }
      });

      if (gelatoIndex > 0 || externalIndex > 0) {
        const res = await fetch(url.toString());
        const newVariants = (await res.json()) as { [variantId: string]: VariantInfo };
        batch(() => {
          dispatch(setDraftsAction(drafts));
          dispatch(setVariantsAction(newVariants));
          variants = getState().drafts.variants;

          drafts.forEach((draft) => {
            if ((!draft.variantId || !variants[draft.variantId]) && !variants[draft.externalVariantId]) {
              // variant deleted by customer
              dispatch(deleteDraftAction(draft.designId));
            }
          });
        });
      } else {
        dispatch(setDraftsAction(drafts));
      }
    } catch (e) {
      captureException(e);
    }
  };

export default loadDraftsOperation;
