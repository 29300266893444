import { PayloadAction } from '@reduxjs/toolkit';

import { MultipleProducts, VariantsState } from 'editor/src/store/variants/types';

import { ProductDataPayload } from './setProductDataReducer';

export type ProductsDataPayload = {
  selectedProductId: string;
  products: {
    [productUid: string]: ProductDataPayload;
  };
};

const setProductsDataReducer = (state: VariantsState, action: PayloadAction<MultipleProducts>) => {
  state.products = action.payload;
};

export default setProductsDataReducer;
